.statement {
  /* padding: 1rem; */
  height: 40vh;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  /* background-image: url("../images/fog2.png"); */
  /* background-size: cover;
  background-repeat: no-repeat; */
  background-color: #44566a;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.low-res {
  justify-self: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.high-res {
  justify-self: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  position: absolute;
}

.epic-statement {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statement h1 {
  font-size: 3rem;
  letter-spacing: 0.05rem;  
}


.statement p {
  font-family: 'Helvetica', sans-serif;
  font-style: italic;
}

.statement h1 span {
  font-size: 3.2rem;
}