.musical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  height: 100vh;
  max-height: 980px;
  background-image: url("../images/dark-broadway.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.musical__items {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.black-filter {
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.musical__video {
  padding: 2rem;
  min-width: 50%;
  height: 100%;
}

.musical__img {
  width: 100%;
  height: 100%;
}

.musical__subtitle {
  justify-content: space-around;
  display: flex;
  width: 50%;
  margin-top: 2rem;
}

.musical__subtitle > p {
  font-size: calc((2.1 - 1) * 1.2vw + 1rem);
}

.musical__iframe {
  width: 100%;
  min-height: 15rem;
  height: 100%;
}

.musical h1 {
  letter-spacing: 0.05rem;
  text-align: start;
  font-size: calc((4.1 - 1) * 1.2vw + 1rem);
  line-height: calc(1.3 * (1 + (1 - 5.1)/25));
  filter: drop-shadow(0px 0px 4px black);
}

.musical__text {
  min-width: 50%;
  padding: 2rem;
}

.musical__text p {
  font-size: calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 1rem);
  word-wrap: break-word;
  font-family: 'Helvetica', sans-serif;
  filter: drop-shadow(0px 0px 4px black);
}
 

@media only screen and (max-width: 768px) {

  .musical__items {
    flex-direction: column;
  }
  
  .musical__subtitle {
    width: 70%;
    margin-bottom: 2rem;
  }

  .musical__subtitle > p{
    margin-bottom: 1rem;
  }
}
