.roadmap {
  padding: 4rem;
  background: #fff;
  background-image: url("../images/colorized13hd.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  max-height: 980px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.roadmap-subscription h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
  text-align: center;
}

.roadmap__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}


.roadmap-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.roadmap-subscription > p {
  font-family: 'Libel', 'sans-serif';
  font-size: 32px;
}

.roadmap-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.roadmap-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.input-areas {
  display: flex;
  flex-direction: column;
}

.input-areas form {
  display: flex;
  flex-direction: column;
}

.input-areas form textarea {
  resize: none;
}

.roadmap-input {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.roadmap-email-form h2 {
  margin-bottom: 2rem;
}

.roadmap-input::placeholder {
  color: #b1b1b1;
}

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--yellow {
  background-color: #FEBF10;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}


@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}


@media screen and (max-width: 820px) {
  .roadmap-links {
    padding-top: 2rem;
  }

  .roadmap-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .roadmap-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .roadmap-logo {
    width: 50%;
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.5));
  }

  .top-social-wrap {
    flex-direction: column;
  }

  .roadmap-subscription h1 {
    font-size: 70px;
    margin-top: 50px;
    text-align: center;
  }

  .roadmap-subscription > p {
    font-size: 30px;
  }
}

@media screen and (max-width: 360px) {
  .roadmap {
    padding: 2rem;
  }
}