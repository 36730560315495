video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: -2;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: calc(100vh - 80px);
  /* max-height: 980px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  position: relative;
}

.hero-container > .hero-island > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > .hero-island > img {
  width: 40%;
  height: 40%;
  object-fit: contain;
}

.hero-container > .hero-island > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  font-family: 'Libel', 'sans-serif';
}

.hero-island {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > .hero-island > h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .hero-container > .hero-island > img {
    width: 50%;
    height: 50%;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > .hero-island > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > .hero-island > p {
    font-size: 30px;
  }

  .hero-container > .hero-island > img {
    width: 80%;
    height: 80%;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
