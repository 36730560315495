.novel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  padding-top: 6rem;
  color: #fff;
  min-height: 100vh;
  /* background-image: url("../images/background2.png"); */
  background-color: #7f0900;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='105' viewBox='0 0 80 105'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='death-star' fill='%23c0362c' fill-opacity='0.28'%3E%3Cpath d='M20 10a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm15 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zM20 75a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zm30-65a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm0 65a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zM35 10a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zM5 45a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zm60 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.novel__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  /* max-width: 1500px; */
  /* width: 90%; */
  margin: 0 auto;
}

.novel__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 50px 0 45px; */
}

.novel__flex {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.novel__column {
  width: 30%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items:baseline;
}

.novel__column p {
  font-family: 'Helvetica', sans-serif;
}

.novel__img {
  width: 100%;
  object-fit: contain;
  margin-block: 1rem;
}

.novel__video {
  padding: 2rem;
  min-width: 50%;
}

.novel__iframe {
  width: 100%;
  min-height: 15rem;
  height: 100%;
}

.novel__text {
  min-width: 50%;
  padding: 2rem;
}

.description {
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  font-style: italic;
}

.novel__subtitle > p {
  font-size: calc((2.1 - 1) * 1.2vw + 1rem);
}

.novel h1 {
  letter-spacing: 0.05rem;
  text-align: start;
  font-size: calc((4.1 - 1) * 1.2vw + 1rem);
  line-height: calc(1.3 * (1 + (1 - 5.1)/25));
}

.novel__text p {
  font-size: calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 1rem);
  word-wrap: break-word;
  font-family: 'Helvetica', sans-serif;
}

.invisible {
  visibility: visible;
}

@media only screen and (max-width: 480px) {
  .novel__flex {
    flex-direction: column;
  }
  .novel__column {
    width: 100%;
  }
  .invisible {
    visibility:collapse;
    height: 0;
    padding: 0;
    margin: 0;
  }
}