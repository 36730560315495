.dream {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  /* padding-top: 6rem; */
  color: #fff;
  height: 100vh;
  max-height: 980px;
  background-image: url("../images/dream4.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.dream__video {
  padding: 2rem;
  min-width: 50%;
}

.dreams__items {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dream__header {
  /* width: 50%; */
}

.dream__text {
  width: 50%;
}

.dream__subtitle {
  justify-content: space-around;
  display: flex;
  width: 50%;
  margin-top: 2rem;
}

.dream__subtitle > p {
  font-size: calc((2.1 - 1) * 1.2vw + 1rem);
}

.dream__iframe {
  width: 100%;
  min-height: 15rem;
  height: 100%;
}

.dream h1 {
  letter-spacing: 0.05rem;
  text-align: start;
  font-size: calc((4.1 - 1) * 1.2vw + 1rem);
  line-height: calc(1.3 * (1 + (1 - 5.1)/25));
  filter: drop-shadow(0px 0px 4px black);
}

.dream__text {
  min-width: 50%;
  padding: 2rem;
}

.dream__text p {
  font-size: calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 1rem);
  word-wrap: break-word;
  font-family: 'Helvetica', sans-serif;
  filter: drop-shadow(0px 0px 4px black);
}
 

@media only screen and (max-width: 768px) {
  .dream__video {
    padding-block: 0;
  }
  
  .dream__subtitle {
    width: 70%;
    margin-bottom: 2rem;
  }

  .dream__subtitle > p{
    margin-bottom: 1rem;
  }

  .dreams__items {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dream__header {
    width: 100%;
    padding: 2rem;
  }
  
  .dream__text {
    width: 100%;
  }  
}
