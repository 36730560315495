.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  align-items: center;
  height: 100vh;
  max-height: 980px;
  background-image: url("../images/background2.png");
}

.top-social-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-logo {
  width: 20%;
  filter: drop-shadow(0 0 16px rgba(0, 0, 0, 0.5));
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items * {
cursor: default;
}
.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  /* margin-left: 20px; */
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  white-space:break-spaces;
  text-align: baseline;
}

.social-logo > span {
  font-family: 'Helvetica', sans-serif;
  text-align: baseline;
  font-size: 1rem;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
  white-space: nowrap;
}

.credits__palette {
  color: white;
  text-align: center;
  padding-block: 0.5rem;
  font-family: 'Helvetica', sans-serif;
  font-style: italic;
  font-size: 0.7rem;
  background: linear-gradient(90deg, rgb(119, 2, 2) 0%, rgb(116, 0, 0) 100%);
  width: 100%;
}

.credits__palette a {
  color: white;
  font-family: 'Helvetica', sans-serif;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .footer-logo {
    width: 50%;
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.5));
  }

  .top-social-wrap {
    flex-direction: column;
  }

  .footer-link-items {
    align-items: center;
  }

  .social-logo {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .social-media-wrap {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
}
